import { computed, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { fnModalAlert } from '@/components/common/modal/alert'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    rewardPoint: 0,
    cardFee: 0,
    cashFee: 0,
    form: {},
    thumbnail: [],
    pinDetail: {},
    beforeBuyer: [],
    conflict: [],
    chatroomId: '',
    runningConflict: computed(() => {
      if (proxy.$Util.isEmpty(state.conflict)) return []
      return proxy.$_.find(state.conflict, { status: '0' })
    }),
    showUseDt: computed(() =>
      state.pinDetail.useDt
        ? proxy.$dayjs(state.pinDetail.useDt).format('YYYY-MM-DD 까지')
        : '기한 없음'
    ),
    viewResOptions: computed(() =>
      state.pinDetail.buyer && state.pinDetail.type == '1' ? true : false
    )
  })

  const fnEdit = async () => {
    await router.replace({
      path: '/my-page/market/edit',
      query: {
        marketNo: state.market.marketNo
      }
    })
  }

  const fnPage = async () => {
    await router.push({ path: '/market/pay', query: { ...state.form } })
  }

  const fnRefund = async () => {
    if (!confirm('취소 환불을 승인하시겠습니까?')) return

    const params = {}
    params.seq = state.runningConflict.seq
    const res = await proxy.$MarketSvc.postMyPageCancelRefundAccept(params)
    if (res.resultCode === '0000') {
      alert('취소 환불되었습니다.')
      await fnDetail()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnMarketRequestApproval = async () => {
    const params = { ...state.params }
    params.pinSeqList = [state.pinDetail.seq]
    const res = await proxy.$MarketSvc.postMyPageMarketRequestApproval(params)
    if (res.resultCode === '0000') {
      alert('구매 확정 요청되었습니다.')
      await fnDetail()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnRefundReject = async (isEdit = 1, item = {}) => {
    const payload = {
      component: proxy.$modalAlertNames.MARKET_REFUND_REJECT,
      data: {
        seq:
          proxy.$ConstCode.BOOLEAN_VALUE.TRUE === isEdit
            ? item.seq
            : state.runningConflict.seq,
        reply: proxy.$ConstCode.BOOLEAN_VALUE.TRUE === isEdit ? item.reply : '',
        isEdit: isEdit
      },
      callback: async () => {
        await fnDetail()
      }
    }
    await fnModalAlert(payload)
  }

  const fnModalAddInfo = async () => {
    const payload = {
      component: proxy.$modalAlertNames.MARKET_ADD_INFO,
      data: state.market,
      callback: async reply => {
        await fnSaveReqOption(reply)
      }
    }
    await fnModalAlert(payload)
  }

  const fnSaveReqOption = async reply => {
    const params = {}
    params.marketNo = state.params.marketNo
    params.reply = reply
    params.memberSeq = state.market.memberSeq
    let res
    if (proxy.$Util.isEmpty(state.market.reply)) {
      res = await proxy.$MarketSvc.postMyPageBuyMarketOptions(params)
    } else {
      res = await proxy.$MarketSvc.postMyPageBuyMarketOptionsUpdate(params)
    }
    if (res.resultCode === '0000') {
      state.market.reply = reply
    } else {
      alert(res.resultMsg)
    }
  }

  const fnBuyerView = async ({ memberSeq }) => {
    await router.push({
      path: '/market/member',
      query: {
        marketNo: state.market.marketNo,
        memberSeq
      }
    })
  }

  const fnDetail = async () => {
    const res = await proxy.$MarketSvc.postMyPageMarketPinDetail(route.query)
    if (res.resultCode === '0000') {
      state.pinDetail = { ...res }
      delete state.pinDetail.resultCode
      delete state.pinDetail.resultMsg
      delete state.pinDetail.conflict
      delete state.pinDetail.beforeBuyer
      delete state.pinDetail.chatroomId

      state.conflict = res.conflict
      state.chatroomId = res.chatroomId
      state.beforeBuyer = res.beforeBuyer
    } else {
      alert(res.resultMsg)
    }
  }

  const fnDelete = async () => {
    const res = await proxy.$MarketSvc.postMyPageBuyMarketDelete(state.params)
    if (res.resultCode === '0000') {
      alert('삭제되었습니다.')
      router.go(-1)
    } else {
      alert(res.resultMsg)
    }
  }

  // TODO: 분쟁 취소?
  const fnCancelArgument = async () => {
    const params = {
      seq: state.runningConflict.seq
    }
    const res = await proxy.$MarketSvc.cancelRefundDelete(params)
    if (res.resultCode === '0000') {
      alert('취소 되었습니다.')
      await fnDetail()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnWizztalk = () => {
    const params = {
      chatroomId: state.chatroomId,
      marketNo: route.query.marketNo,
      memberSeq: route.query.memberSeq
    }
    router.push({
      path: '/market/wizz-talk',
      query: params
    })
  }

  const fnBeforeBuyer = aBuyer => {
    router.push({
      name: 'my-page-market-view-detail-before-buyer-index',
      params: {
        prev: aBuyer.memberSeq
      },
      query: {
        pinSeq: state.pinDetail.seq,
        marketNo: route.query.marketNo
      }
    })
  }

  const getStatusStyle = status =>
    status == 2 || status == 4 || status == 5
      ? { done: true }
      : status == 1
      ? { waiting: true }
      : status == 0
      ? {}
      : { conflict: true }

  const init = async () => {
    await fnDetail()
  }

  init()

  return {
    ...toRefs(state),
    fnPage,
    fnModalAddInfo,
    fnRefund,
    fnRefundReject,
    fnEdit,
    fnBuyerView,
    fnDetail,
    fnDelete,
    fnCancelArgument,
    fnWizztalk,
    getStatusStyle,
    fnBeforeBuyer,
    fnMarketRequestApproval
  }
}
